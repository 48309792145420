import { gsap } from 'gsap';

export function usePageTransition() {
    return {
        name: 'page-transiton',
        mode: 'out-in',
        css: false,
        onBeforeEnter: () => {
            const store = useGlobalStore();
            store.setPageTransitionBeforeComplete(true);
            store.setToggleSearchForm(false);
        },
        onEnter: (el, done) => {
            const store = useGlobalStore();
            gsap.timeline({
                onComplete: () => {
                    store.setPageTransitionComplete(true);
                    document.body.dataset.scrollDirection = 'init';
                    document.body.dataset.scrolled = 'false';
                    document.body.classList.remove('is-loading');
                    document.body.classList.remove("hide-pointer-events");
                    done();
                },
            })
                .to(
                    '.p-page-loader .p-page-loader--screen',
                    {
                        duration: 1,
                        yPercent: -100,
                        ease: "Expo.easeInOut"
                    },
                    "<"
                )
                .to(
                    '.p-page-loader .shape-round.top',
                    {
                        duration: 0.4,
                        scaleY: 1,
                        ease: "Expo.easeIn"
                    },
                    "<"
                )
                .to(
                    '.p-page-loader .shape-round.top',
                    {
                        duration: 0.4,
                        scaleY: 0,
                        ease: "Expo.easeOut"
                    },
                    "< 0.5"
                )

        },
        onBeforeLeave: (el) => {
            const store = useGlobalStore();
            store.setPageTransitionBeforeComplete(false)

            document.body.classList.add('is-loading');
            document.body.classList.add("hide-pointer-events");

            gsap.set('.p-page-loader .p-page-loader--screen', {
                opacity: 1,
                yPercent: 100
            })

            gsap.set('.p-page-loader .shape-round.bottom', {
                scaleY: 0
            })

            gsap.set('.p-page-loader .shape-round.top', {
                scaleY: 0
            })
        },
        onLeave: (el, done) => {
            const store = useGlobalStore();

            gsap.timeline({
                onComplete: () => {
                    store.setPageTransitionComplete(false);
                    store.setToggleMobileMenu(false);
                    done();
                },
            })

                .to('.p-page-loader--screen', {
                    duration: 0.6,
                    yPercent: 0,
                    ease: "Power4.easeInOut"
                })
                .to(
                    '.p-page-loader .shape-round.bottom',
                    {
                        duration: 0.2,
                        scaleY: 1,
                        ease: "Expo.easeIn"
                    },
                    "<"
                )
                .to(
                    '.p-page-loader .shape-round.bottom',
                    {
                        duration: 0.2,
                        scaleY: 0,
                        ease: "Expo.easeOut"
                    },
                    "< 0.2"
                )
                .to(
                    '.p-page-loader .shape-round.bottom',
                    {
                        duration: 0.2,
                        scaleY: 0,
                        ease: "Expo.easeOut"
                    },
                    "< 0.2"
                )
                .to(
                    '.p-page-loader .shape-round.bottom',
                    {
                        duration: 0.2,
                        scaleY: 0,
                        ease: "Expo.easeOut"
                    },
                    "< 0.2"
                )
        }
    }
}