export default function (active, listMenus) {
    const { locales } = useI18n();

    const menus = {};
    const menuKit = {
        'development': [
            'menu_general',
            'menu_links'
        ],
        'consultancy': [
            'menu_general',
            'menu_company',
            'menu_resources',
            'menu_links'
        ],
        'group': [
            'menu_general',
            'menu_footer',
            'menu_links'
        ]
    }[active];


    if (menuKit) {
        locales.value.forEach(l => {
            const groups = {};
            menuKit.forEach(menu => groups[menu] = listMenus[menu].filter(item => item.language === l.code));
            menus[l.code] = groups;
        })
    }

    return menus;
}