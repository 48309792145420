export function getSdk(client, withWrapper = (action, _operationName, _operationType) => action()) {
  return {
    categoryInsights: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query categoryInsights($siteId: [QueryArgument]) {
    categoryInsights: categories(group: "insights", level: 1, siteId: $siteId) {
        id
        title
        slug
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'categoryInsights', 'query'),
    categoryProjects: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query categoryProjects($siteId: [QueryArgument]) {
    categoryProjects: categories(group: "project", level: 1, siteId: $siteId) {
        id
        title
        slug
        ... on project_Category {
            icon {
                id
                title
                url
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'categoryProjects', 'query'),
    teamCategories: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query teamCategories($siteId: [QueryArgument]) {
    teamCategories: categories(group: "team", level: 1, siteId: $siteId) {
        id
        title
        slug
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'teamCategories', 'query'),
    chome: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query chome($siteId: [QueryArgument]) {
    home: entry(section: "homeConsultancy", siteId: $siteId) {
        id
        title
        typeHandle
        language
        localized {
            language
            slug
        }
        ... on homeConsultancy_Entry {
            heroHeadline
            heroDescription
            heroMedia @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            heroVideo {
                id
                title @parseRefs
                url
                kind
            }
            heroLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            heroScrollto {
                title
                target
            }
            introText @parseRefs
            typesDescription @parseRefs
            projectTypes {
                id
                title
                slug
                ... on project_Category {
                    icon {
                        id
                        title
                        url
                    }
                }
            }
            typesLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            text @parseRefs
            mediaImage @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            mediaVideo {
                id
                title @parseRefs
                url
                kind
            }
            customList {
                value
            }
            projectsTitle
            projects {
                id
                title
                slug
                ... on project_Entry {
                    projectMedia {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 840
                                height: 1120
                                quality: 75
                                immediately: true
                            )
                    }
                    projectType {
                        id
                        title
                        slug
                    }
                }
            }
            partnersText @parseRefs
            partners {
                id
                title
                url
                width
                optimize: url @transform(quality: 75, width: 360, mode: "fit", immediately: true)
            }
            newsTitle @parseRefs
            news {
                id
                title
                slug
                ... on insights_Entry {
                    previewImage {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", quality: 75)
                        optimize: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 840
                                height: 550
                                quality: 75
                                immediately: true
                            )
                    }
                    insightsType {
                        id
                        title
                        slug
                    }
                }
            }
            accordionTitle @parseRefs
            accordionText
            accordionItems {
                ... on accordion_Entry {
                    id
                    title
                    description
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                }
            }
            joinTitle @parseRefs
            joinText
            joinLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            joinMedia {
                id
                title @parseRefs
                url
                kind
                webp: url
                    @transform(format: "webp", quality: 75, width: 900, height: 1150, mode: "crop", position: "center")
                webpsmall: url
                    @transform(format: "webp", quality: 75, width: 630, height: 630, mode: "fit", position: "center")
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'chome', 'query'),
    cmenus: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query cmenus($siteId: [QueryArgument]) {
    menu_general: navigationNodes(navHandle: "consultancy", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        ... on consultancy_Node {
            fullWidth
            previewImage {
                id
                title
                url
                webp: url @transform(mode: "crop", format: "webp", width: 276, height: 176, quality: 75)
            }
        }
        element {
            id
            slug
            ... on homeConsultancy_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutConsultancyTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutGroupTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on teamTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on careersTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on projectOverview_Entry {
                sectionHandle
                typeHandle
            }
            ... on insightsOverview_Entry {
                sectionHandle
                typeHandle
            }
            ... on insights_Entry {
                sectionHandle
                typeHandle
            }
            ... on project_Entry {
                sectionHandle
                typeHandle
            }
            ... on solution_Entry {
                sectionHandle
                typeHandle
            }
            ... on solutions_Entry {
                sectionHandle
                typeHandle
            }
            ... on vacancies_Entry {
                sectionHandle
                typeHandle
            }
        }
        children {
            id
            title
            url
            classes
            typeLabel
            newWindow
            ... on consultancy_Node {
                fullWidth
                previewImage {
                    id
                    title
                    url
                    webp: url @transform(mode: "crop", format: "webp", width: 276, height: 176, quality: 75)
                }
            }
            element {
                id
                slug
                ... on homeConsultancy_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on contactTemplate_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on aboutConsultancyTemplate_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on aboutGroupTemplate_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on teamTemplate_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on contentTemplate_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on careersTemplate_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on projectOverview_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on insightsOverview_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on insights_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on project_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on solution_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on solutions_Entry {
                    sectionHandle
                    typeHandle
                }
                ... on vacancies_Entry {
                    sectionHandle
                    typeHandle
                }
            }
        }
    }
    menu_company: navigationNodes(navHandle: "consultancyFooterCompany", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        element {
            id
            slug
            ... on homeConsultancy_Entry {
                sectionHandle
                typeHandle
            }
            ... on homeGroup_Entry {
                sectionHandle
                typeHandle
            }
            ... on homeDevelopment_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutConsultancyTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutGroupTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on teamTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on careersTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on projectOverview_Entry {
                sectionHandle
                typeHandle
            }
            ... on insightsOverview_Entry {
                sectionHandle
                typeHandle
            }
            ... on insights_Entry {
                sectionHandle
                typeHandle
            }
            ... on project_Entry {
                sectionHandle
                typeHandle
            }
            ... on solution_Entry {
                sectionHandle
                typeHandle
            }
            ... on vacancies_Entry {
                sectionHandle
                typeHandle
            }
        }
    }
    menu_resources: navigationNodes(navHandle: "consultancyFooterResources", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        element {
            id
            slug
            ... on homeConsultancy_Entry {
                sectionHandle
                typeHandle
            }
            ... on homeGroup_Entry {
                sectionHandle
                typeHandle
            }
            ... on homeDevelopment_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutConsultancyTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutGroupTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on teamTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on careersTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on projectOverview_Entry {
                sectionHandle
                typeHandle
            }
            ... on insightsOverview_Entry {
                sectionHandle
                typeHandle
            }
            ... on insights_Entry {
                sectionHandle
                typeHandle
            }
            ... on project_Entry {
                sectionHandle
                typeHandle
            }
            ... on solution_Entry {
                sectionHandle
                typeHandle
            }
            ... on vacancies_Entry {
                sectionHandle
                typeHandle
            }
        }
    }
    menu_links: navigationNodes(navHandle: "consultancyFooterLinks", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        element {
            id
            slug
            ... on homeConsultancy_Entry {
                sectionHandle
                typeHandle
            }
            ... on homeGroup_Entry {
                sectionHandle
                typeHandle
            }
            ... on homeDevelopment_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutConsultancyTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutGroupTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on teamTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on careersTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on projectOverview_Entry {
                sectionHandle
                typeHandle
            }
            ... on insightsOverview_Entry {
                sectionHandle
                typeHandle
            }
            ... on insights_Entry {
                sectionHandle
                typeHandle
            }
            ... on project_Entry {
                sectionHandle
                typeHandle
            }
            ... on solution_Entry {
                sectionHandle
                typeHandle
            }
            ... on vacancies_Entry {
                sectionHandle
                typeHandle
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cmenus', 'query'),
    cpage: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query cpage($section: [String], $siteId: [QueryArgument], $slug: [String]) {
    cpage: entry(section: $section, siteId: $siteId, slug: $slug) {
        id
        title
        slug
        typeHandle
        language
        localized {
            language
            slug
        }
        ... on contactTemplate_Entry {
            contactHeadline @parseRefs
            contactText @parseRefs
            contactInformation {
                ariaLabel
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            officeHeadline @parseRefs
            officeText @parseRefs
            officeMedia {
                id
                title @parseRefs
                url
                kind
                webp: url @transform(format: "webp", quality: 75, immediately: true)
            }
            officesList {
                ... on office_Entry {
                    id
                    title
                    address
                    items {
                        ... on itemContent_Entry {
                            id
                            title
                            text @parseRefs
                        }
                    }
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on contentTemplate_Entry {
            introLabel @parseRefs
            singleImage @transform(format: "webp", width: 2560, quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            contentElements {
                ... on body_Entry {
                    id
                    text @parseRefs
                    typeHandle
                }
                ... on contactForm_Entry {
                    id
                    title
                    typeHandle
                    mediaImage {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", quality: 75, width: 500, height: 600, mode: "crop")
                    }
                    file {
                        id
                        title
                        filename
                        url
                    }
                    description @parseRefs
                    privacy
                    recipients {
                        email
                    }
                }
                ... on contactPerson_Entry {
                    id
                    headline @parseRefs
                    typeHandle
                    directLink {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    description
                    contactPerson {
                        ... on team_Entry {
                            id
                            title
                            employeePhoto {
                                id
                                title
                                url
                                webp: url @transform(format: "webp", quality: 75)
                            }
                            employeeEmail
                            employeePosition
                            employeeLinkedin
                            employeeDescription @parseRefs
                        }
                    }
                    showEmail
                }
                ... on cta_Entry {
                    id
                    title
                    typeHandle
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    simple
                    color
                }
                ... on gallery_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", width: 1600, mode: "crop", quality: 75)
                    }
                }
                ... on imageLine_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        ... on AssetInterface {
                            extension
                        }
                        webp: url @transform(format: "webp", quality: 75)
                    }
                }
                ... on embed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on image_Entry {
                    id
                    title
                    typeHandle
                    smaller
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                }
                ... on relatedItems_Entry {
                    id
                    title
                    typeHandle
                    related {
                        id
                        title
                        slug
                        typeHandle
                        postDate @formatDateTime(format: "d-m-Y")
                        ... on project_Entry {
                            projectType {
                                id
                                title
                                slug
                            }
                            projectMedia {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url
                                    @transform(mode: "crop", format: "webp", width: 900, height: 1100, quality: 75)
                            }
                        }
                        ... on insights_Entry {
                            previewImage {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url @transform(mode: "crop", format: "webp", width: 900, height: 600, quality: 75)
                            }
                            insightsType {
                                id
                                title
                                slug
                            }
                        }
                    }
                }
                ... on results_Entry {
                    id
                    title
                    typeHandle
                    statistics {
                        value
                        standartLabel
                        highlightedLabel
                    }
                }
                ... on video_Entry {
                    id
                    title
                    typeHandle
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                    mediaVideo {
                        id
                        title
                        url
                        kind
                    }
                    videoLink
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on aboutConsultancyTemplate_Entry {
            heroHeadline
            heroDescription
            heroMedia @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            heroVideo {
                id
                title @parseRefs
                url
                kind
            }
            heroLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            heroScrollto {
                title
                target
            }
            introText @parseRefs
            missionTitle @parseRefs
            missionText @parseRefs
            videoPoster {
                id
                title
                slug
                url
                webp: url @transform(format: "webp", quality: 75, immediately: true)
            }
            videoTitle @parseRefs
            videoUrl
            text @parseRefs
            aboutStatistics @parseRefs
            statisticsLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            statistics {
                ... on statistics_TableRow {
                    value
                    standartLabel
                    highlightedLabel
                }
            }
            mediaImage @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            mediaVideo {
                id
                title @parseRefs
                url
                kind
            }
            links {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            highlightsLabel
            highlightsLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            highlights {
                ... on highlights_Entry {
                    title
                    description
                    icon {
                        id
                        title
                        url
                    }
                }
            }
            conceptualLabel @parseRefs
            conceptualHeadline @parseRefs
            conceptualText @parseRefs
            joinTitle @parseRefs
            joinText @parseRefs
            joinMedia {
                id
                title
                slug
                url
                webp: url
                    @transform(format: "webp", quality: 75, width: 900, height: 1150, mode: "crop", position: "center")
                webpsmall: url
                    @transform(format: "webp", quality: 75, width: 630, height: 630, mode: "fit", position: "center")
            }
            joinLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on careersTemplate_Entry {
            heroHeadline
            heroDescription
            heroMedia @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            heroVideo {
                id
                title @parseRefs
                url
                kind
            }
            heroLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            heroScrollto {
                title
                target
            }
            introText @parseRefs
            gallery @transform(format: "webp", width: 1600, quality: 75) {
                id
                title @parseRefs
                url
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }

            conceptualLabel @parseRefs
            conceptualHeadline @parseRefs
            conceptualText @parseRefs
            text @parseRefs
            mediaImage @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            mediaVideo {
                id
                title @parseRefs
                url
                kind
            }
            customList {
                value
            }
            description @parseRefs
            postitionTitle @parseRefs
            positions {
                id
                title
                slug
                ... on vacancies_Entry {
                    vacancyPosition
                    vacancyLocation
                    vacancyDescription
                    information
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on insightsOverview_Entry {
            introText @parseRefs
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on projectOverview_Entry {
            introText @parseRefs
            slogan {
                ... on slogan_Entry {
                    description
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on teamTemplate_Entry {
            pageHeadline
            pageDescription
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cpage', 'query'),
    dhome: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query dhome($siteId: [QueryArgument]) {
    home: entry(section: "homeDevelopment", siteId: $siteId) {
        id
        title
        typeHandle
        ... on homeDevelopment_Entry {
            heroHeadline
            heroDescription
            heroMedia @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            heroVideo {
                id
                title @parseRefs
                url
                kind
            }
            heroLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            heroScrollto {
                title
                target
            }
            introLabel @parseRefs
            introText @parseRefs
            projectsTitle @parseRefs
            projects {
                id
                title
                slug
                ... on project_Entry {
                    text @parseRefs
                    statistics {
                        value
                        standartLabel
                        highlightedLabel
                    }
                    projectType {
                        id
                        title
                        slug
                    }
                    projectMedia {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(mode: "crop", format: "webp", width: 1100, height: 1100, quality: 75)
                    }
                }
            }
            statistics {
                value
                standartLabel
                highlightedLabel
            }
            headline @parseRefs
            text @parseRefs
            mediaImage @transform(format: "webp", width: 2560, height: 2560, quality: 75) {
                id
                title @parseRefs
                url
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            mediaVideo {
                id
                title @parseRefs
                url
                kind
            }
            teamTitle @parseRefs
            team {
                ... on team_Entry {
                    id
                    title
                    employeePhoto {
                        id
                        title
                        url
                        webp: url
                            @transform(
                                format: "webp"
                                quality: 75
                                width: 750
                                height: 750
                                mode: "crop"
                                position: "center"
                            )
                    }
                    employeeEmail
                    employeePosition
                    employeeLinkedin
                    employeeDescription @parseRefs
                }
            }
            joinTitle @parseRefs
            joinText @parseRefs
            joinMedia {
                id
                title
                slug
                url
                webp: url
                    @transform(format: "webp", quality: 75, width: 900, height: 1150, mode: "crop", position: "center")
                webpsmall: url
                    @transform(format: "webp", quality: 75, width: 630, height: 630, mode: "fit", position: "center")
            }
            joinLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dhome', 'query'),
    dmenus: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query dmenus($siteId: [QueryArgument]) {
    menu_general: navigationNodes(navHandle: "development", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        customAttributes {
            attribute
            value
        }
        element {
            id
            slug
            ... on homeDevelopment_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on projectOverview_Entry {
                sectionHandle
                typeHandle
            }
        }
    }
    menu_links: navigationNodes(navHandle: "developmentFooterLinks", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        element {
            id
            slug
            ... on homeDevelopment_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on projectOverview_Entry {
                sectionHandle
                typeHandle
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dmenus', 'query'),
    dpage: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query dpage($section: [String], $siteId: [QueryArgument], $slug: [String]) {
    dpage: entry(section: $section, siteId: $siteId, slug: $slug) {
        id
        title
        slug
        typeHandle
        ... on contactTemplate_Entry {
            contactHeadline @parseRefs
            contactText @parseRefs
            contactInformation {
                ariaLabel
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            officeHeadline @parseRefs
            officeText @parseRefs
            officeMedia {
                id
                title @parseRefs
                url
                kind
                webp: url @transform(format: "webp", quality: 75, immediately: true)
            }
            officesList {
                ... on office_Entry {
                    id
                    title
                    address
                    items {
                        ... on itemContent_Entry {
                            id
                            title
                            text @parseRefs
                        }
                    }
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on contentTemplate_Entry {
            introLabel @parseRefs
            singleImage @transform(format: "webp", width: 2560, quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            contentElements {
                ... on body_Entry {
                    id
                    text @parseRefs
                    typeHandle
                }
                ... on contactForm_Entry {
                    id
                    title
                    typeHandle
                    mediaImage {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", quality: 75, width: 500, height: 600, mode: "crop")
                    }
                    file {
                        id
                        title
                        filename
                        url
                    }
                    description @parseRefs
                    privacy
                    recipients {
                        email
                    }
                }
                ... on contactPerson_Entry {
                    id
                    headline @parseRefs
                    typeHandle
                    directLink {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    description
                    contactPerson {
                        ... on team_Entry {
                            id
                            title
                            employeePhoto {
                                id
                                title
                                url
                                webp: url @transform(format: "webp", quality: 75)
                            }
                            employeeEmail
                            employeePosition
                            employeeLinkedin
                            employeeDescription @parseRefs
                        }
                    }
                    showEmail
                }
                ... on cta_Entry {
                    id
                    title
                    typeHandle
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    simple
                    color
                }
                ... on gallery_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", width: 1600, mode: "crop", quality: 75)
                    }
                }
                ... on imageLine_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        ... on AssetInterface {
                            extension
                        }
                        webp: url @transform(format: "webp", quality: 75)
                    }
                }
                ... on embed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on image_Entry {
                    id
                    title
                    typeHandle
                    smaller
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                }
                ... on relatedItems_Entry {
                    id
                    title
                    typeHandle
                    related {
                        id
                        title
                        slug
                        typeHandle
                        postDate @formatDateTime(format: "d-m-Y")
                        ... on project_Entry {
                            projectType {
                                id
                                title
                                slug
                            }
                            projectMedia {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url
                                    @transform(mode: "crop", format: "webp", width: 900, height: 1100, quality: 75)
                            }
                        }
                        ... on insights_Entry {
                            previewImage {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url @transform(mode: "crop", format: "webp", width: 900, height: 600, quality: 75)
                            }
                            insightsType {
                                id
                                title
                                slug
                            }
                        }
                    }
                }
                ... on results_Entry {
                    id
                    title
                    typeHandle
                    statistics {
                        value
                        standartLabel
                        highlightedLabel
                    }
                }
                ... on video_Entry {
                    id
                    title
                    typeHandle
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                    mediaVideo {
                        id
                        title
                        url
                        kind
                    }
                    videoLink
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on projectOverview_Entry {
            introText @parseRefs
            slogan {
                ... on slogan_Entry {
                    description
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dpage', 'query'),
    employeeinsights: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query employeeinsights($siteId: [QueryArgument], $employeeId: [QueryArgument]) {
    insights: entries(section: "insights", siteId: $siteId, team: ":notempty:", relatedToEntries: { id: $employeeId }) {
        ... on insights_Entry {
            id
            title
            slug
            previewImage {
                id
                title
                url
                webp: url @transform(mode: "crop", format: "webp", width: 245, height: 155, quality: 75)
            }
            team {
                ... on team_Entry {
                    id
                    title
                    slug
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'employeeinsights', 'query'),
    employees: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query employees($siteId: [QueryArgument]) {
    employees: entries(
        section: "team"
        siteId: $siteId
        relatedToCategories: { excludedFromNavigation: false, group: "team" }
    ) {
        ... on team_Entry {
            id
            title
            slug
            employeeGroup {
                id
                title
                slug
                lft
                ... on team_Category {
                    excludedFromNavigation
                }
            }
            employeePhoto {
                id
                title
                url
                webp: url @transform(mode: "crop", format: "webp", width: 650, height: 650, quality: 75)
            }
            employeeEmail
            employeePosition
            employeeLinkedin
            employeeDescription @parseRefs
        }
    }
    teamCategories: categories(
        group: "team"
        level: 1
        excludedFromNavigation: false
        siteId: $siteId
        orderBy: "lft ASC"
    ) {
        id
        title
        slug
        lft
        rgt
        ... on team_Category {
            excludedFromNavigation
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'employees', 'query'),
    ghome: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query ghome($siteId: [QueryArgument]) {
    home: entry(section: "homeGroup", siteId: $siteId) {
        id
        title
        typeHandle
        ... on homeGroup_Entry {
            heroHeadline
            heroDescription
            heroMedia @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            heroVideo {
                id
                title @parseRefs
                url
                kind
            }
            heroLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            heroScrollto {
                title
                target
            }
            introText @parseRefs
            tilesTitle
            tiles {
                ... on tile_Entry {
                    id
                    title
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                    mediaVideo {
                        id
                        title @parseRefs
                        url
                        kind
                    }
                    directLink {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                }
            }
            aboutStatistics
            statisticsLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            statistics {
                value
                standartLabel
                highlightedLabel
            }
            bannerTitle
            bannerMedia {
                id
                title @parseRefs
                url
                kind
                webp: url @transform(format: "webp", quality: 75)
            }
            bannerVideo {
                id
                title @parseRefs
                url
                kind
            }
            bannerDescription @parseRefs
            highlightsLabel
            highlightsLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            highlights {
                ... on highlights_Entry {
                    title
                    description
                    icon {
                        id
                        title
                        url
                    }
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ghome', 'query'),
    gmenus: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query gmenus($siteId: [QueryArgument]) {
    menu_general: navigationNodes(navHandle: "group", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        element {
            id
            slug
            ... on homeGroup_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutGroupTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
        }
    }
    menu_footer: navigationNodes(navHandle: "groupFooterMenu", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        element {
            id
            slug
            ... on homeGroup_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutGroupTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
        }
    }
    menu_links: navigationNodes(navHandle: "groupFooterLinks", level: 1, siteId: $siteId) {
        id
        title
        url
        classes
        typeLabel
        newWindow
        language
        element {
            id
            slug
            ... on homeGroup_Entry {
                sectionHandle
                typeHandle
            }
            ... on aboutGroupTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contactTemplate_Entry {
                sectionHandle
                typeHandle
            }
            ... on contentTemplate_Entry {
                sectionHandle
                typeHandle
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'gmenus', 'query'),
    gpage: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query gpage($section: [String], $siteId: [QueryArgument], $slug: [String]) {
    gpage: entry(section: $section, siteId: $siteId, slug: $slug) {
        id
        title
        slug
        typeHandle
        ... on contactTemplate_Entry {
            contactHeadline @parseRefs
            contactText @parseRefs
            contactInformation {
                ariaLabel
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            officeHeadline @parseRefs
            officeText @parseRefs
            officeMedia {
                id
                title @parseRefs
                url
                kind
                webp: url @transform(format: "webp", quality: 75, width: 2560, immediately: true)
            }
            officesList {
                ... on office_Entry {
                    id
                    title
                    address
                    items {
                        ... on itemContent_Entry {
                            id
                            title
                            text @parseRefs
                        }
                    }
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on contentTemplate_Entry {
            introLabel @parseRefs
            singleImage @transform(format: "webp", width:2560, quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            contentElements {
                ... on body_Entry {
                    id
                    text @parseRefs
                    typeHandle
                }
                ... on contactForm_Entry {
                    id
                    title
                    typeHandle
                    mediaImage {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", quality: 75, width: 500, height: 600, mode: "crop")
                    }
                    file {
                        id
                        title
                        filename
                        url
                    }
                    description @parseRefs
                    privacy
                    recipients {
                        email
                    }
                }
                ... on contactPerson_Entry {
                    id
                    headline @parseRefs
                    typeHandle
                    directLink {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    description
                    contactPerson {
                        ... on team_Entry {
                            id
                            title
                            employeePhoto {
                                id
                                title
                                url
                                webp: url @transform(format: "webp", quality: 75)
                            }
                            employeeEmail
                            employeePosition
                            employeeLinkedin
                            employeeDescription @parseRefs
                        }
                    }
                    showEmail
                }
                ... on cta_Entry {
                    id
                    title
                    typeHandle
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    simple
                    color
                }
                ... on gallery_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", width: 1600, mode: "crop", quality: 75)
                    }
                }
                ... on imageLine_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        ... on AssetInterface {
                            extension
                        }
                        webp: url @transform(format: "webp", quality: 75)
                    }
                }
                ... on embed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on image_Entry {
                    id
                    title
                    typeHandle
                    smaller
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                }
                ... on relatedItems_Entry {
                    id
                    title
                    typeHandle
                    related {
                        id
                        title
                        slug
                        typeHandle
                        postDate @formatDateTime(format: "d-m-Y")
                        ... on project_Entry {
                            projectType {
                                id
                                title
                                slug
                            }
                            projectMedia {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url
                                    @transform(mode: "crop", format: "webp", width: 900, height: 1100, quality: 75)
                            }
                        }
                        ... on insights_Entry {
                            previewImage {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url @transform(mode: "crop", format: "webp", width: 900, height: 600, quality: 75)
                            }
                            insightsType {
                                id
                                title
                                slug
                            }
                        }
                    }
                }
                ... on results_Entry {
                    id
                    title
                    typeHandle
                    statistics {
                        value
                        standartLabel
                        highlightedLabel
                    }
                }
                ... on video_Entry {
                    id
                    title
                    typeHandle
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                    mediaVideo {
                        id
                        title
                        url
                        kind
                    }
                    videoLink
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
        ... on aboutGroupTemplate_Entry {
            heroLabel @parseRefs
            heroHeadline @parseRefs
            heroDescription @parseRefs
            heroMedia @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            heroVideo {
                id
                title @parseRefs
                url
                kind
            }
            heroLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            heroScrollto {
                title
                target
            }
            introText @parseRefs
            missionTitle @parseRefs
            missionText @parseRefs
            statisticsTitle
            statistics {
                value
                standartLabel
                highlightedLabel
            }
            bannerTitle
            bannerMedia {
                id
                title @parseRefs
                url
                kind
                webp: url @transform(format: "webp", width: 2560, mode: "crop", quality: 75)
            }
            bannerVideo {
                id
                title @parseRefs
                url
                kind
            }
            conceptualLabel @parseRefs
            conceptualHeadline @parseRefs
            conceptualText @parseRefs
            headline @parseRefs
            text @parseRefs
            mediaImage @transform(format: "webp", width: 2560, height: 2560, quality: 75) {
                id
                title @parseRefs
                url
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            mediaVideo {
                id
                title @parseRefs
                url
                kind
            }
            highlightsLabel
            highlightsLinks {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            highlights {
                ... on highlights_Entry {
                    title
                    description
                    icon {
                        id
                        title
                        url
                    }
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'gpage', 'query'),
    insight: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query insight($siteId: [QueryArgument], $slug: [String]) {
    insight: entry(section: "insights", siteId: $siteId, slug: $slug) {
        ... on insights_Entry {
            id
            title
            slug
            typeHandle
            language
            localized {
                language
                slug
            }
            singleImage {
                id
                title
                url
                webpdesktop: url @transform(format: "webp", mode: "crop", width: 1900, height: 1300, quality: 80)
                webpmobile: url @transform(format: "webp", mode: "crop", width: 1300, height: 1900, quality: 80)
            }
            team {
                id
                title
                ... on team_Entry {
                    employeePhoto {
                        id
                        title
                        url
                        webp: url @transform(mode: "crop", format: "webp", width: 650, height: 650, quality: 75)
                    }
                    employeeEmail
                    employeePosition
                    employeeLinkedin
                    employeeDescription @parseRefs
                }
            }
            contentElements {
                ... on body_Entry {
                    id
                    text @parseRefs
                    typeHandle
                }
                ... on contactForm_Entry {
                    id
                    title
                    typeHandle
                    mediaImage {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", quality: 75, width: 500, height: 600, mode: "crop")
                    }
                    file {
                        id
                        title
                        filename
                        url
                    }
                    description @parseRefs
                    privacy
                    recipients {
                        email
                    }
                }
                ... on contactPerson_Entry {
                    id
                    headline @parseRefs
                    typeHandle
                    directLink {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    description
                    contactPerson {
                        ... on team_Entry {
                            id
                            title
                            employeePhoto {
                                id
                                title
                                url
                                webp: url @transform(format: "webp", quality: 75)
                            }
                            employeeEmail
                            employeePosition
                            employeeLinkedin
                            employeeDescription @parseRefs
                        }
                    }
                    showEmail
                }
                ... on cta_Entry {
                    id
                    title
                    typeHandle
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    simple
                    color
                }
                ... on gallery_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", width: 1600, mode: "crop", quality: 75)
                    }
                }
                ... on imageLine_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        ... on AssetInterface {
                            extension
                        }
                        webp: url @transform(format: "webp", quality: 75)
                    }
                }
                ... on embed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on image_Entry {
                    id
                    title
                    typeHandle
                    smaller
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                }
                ... on relatedItems_Entry {
                    id
                    title
                    typeHandle
                    related {
                        id
                        title
                        slug
                        typeHandle
                        postDate @formatDateTime(format: "d-m-Y")
                        ... on project_Entry {
                            projectType {
                                id
                                title
                                slug
                            }
                            projectMedia {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url
                                    @transform(mode: "crop", format: "webp", width: 900, height: 1100, quality: 75)
                            }
                        }
                        ... on insights_Entry {
                            previewImage {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url @transform(mode: "crop", format: "webp", width: 900, height: 600, quality: 75)
                            }
                            insightsType {
                                id
                                title
                                slug
                            }
                        }
                    }
                }
                ... on results_Entry {
                    id
                    title
                    typeHandle
                    statistics {
                        value
                        standartLabel
                        highlightedLabel
                    }
                }
                ... on video_Entry {
                    id
                    title
                    typeHandle
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                    mediaVideo {
                        id
                        title
                        url
                        kind
                    }
                    videoLink
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insight', 'query'),
    insights: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query insights($siteId: [QueryArgument], $offset: Int) {
    insights: entries(
        section: "insights"
        siteId: $siteId
        offset: $offset
        limit: 36
    ) {
        ... on insights_Entry {
            id
            title
            slug
            typeHandle
            insightsType {
                id
                title
                slug
            }
            postDate @formatDateTime(format: "d-m-Y")
            previewImage {
                id
                title @parseRefs
                kind
                webp: url
                    @transform(mode: "crop", format: "webp", width: 900, height: 600, quality: 75, immediately: false)
            }
        }
    }
    insightsCount: entryCount(section: "insights", siteId: $siteId)
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'insights', 'query'),
    project: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query project($siteId: [QueryArgument], $slug: [String]) {
    project: entry(section: "projects", siteId: $siteId, slug: $slug) {
        ... on project_Entry {
            id
            title
            slug
            typeHandle
            language
            localized {
                language
                slug
            }
            projectStart
            projectClient
            projectCountry
            description @parseRefs
            projectType {
                id
                title
                slug
            }
            mediaImage @transform(format: "webp", quality: 75) {
                id
                title @parseRefs
                url
                webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                srcset(sizes: ["1180", "1920", "2560", "3840"])
            }
            mediaVideo {
                id
                title @parseRefs
                url
                kind
            }
            contentElements {
                ... on body_Entry {
                    id
                    text @parseRefs
                    typeHandle
                }
                ... on contactForm_Entry {
                    id
                    title
                    typeHandle
                    mediaImage {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", quality: 75, width: 500, height: 600, mode: "crop")
                    }
                    file {
                        id
                        title
                        filename
                        url
                    }
                    description @parseRefs
                    privacy
                    recipients {
                        email
                    }
                }
                ... on contactPerson_Entry {
                    id
                    headline @parseRefs
                    typeHandle
                    directLink {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    description
                    contactPerson {
                        ... on team_Entry {
                            id
                            title
                            employeePhoto {
                                id
                                title
                                url
                                webp: url @transform(format: "webp", quality: 75)
                            }
                            employeeEmail
                            employeePosition
                            employeeLinkedin
                            employeeDescription @parseRefs
                        }
                    }
                    showEmail
                }
                ... on cta_Entry {
                    id
                    title
                    typeHandle
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    simple
                    color
                }
                ... on gallery_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", width: 1600, mode: "crop", quality: 75)
                    }
                }
                ... on imageLine_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        ... on AssetInterface {
                            extension
                        }
                        webp: url @transform(format: "webp", quality: 75)
                    }
                }
                ... on embed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on image_Entry {
                    id
                    title
                    typeHandle
                    smaller
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                }
                ... on relatedItems_Entry {
                    id
                    title
                    typeHandle
                    related {
                        id
                        title
                        slug
                        typeHandle
                        postDate @formatDateTime(format: "d-m-Y")
                        ... on project_Entry {
                            projectType {
                                id
                                title
                                slug
                            }
                            projectMedia {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url
                                    @transform(mode: "crop", format: "webp", width: 900, height: 1100, quality: 75)
                            }
                        }
                        ... on insights_Entry {
                            previewImage {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url @transform(mode: "crop", format: "webp", width: 900, height: 600, quality: 75)
                            }
                            insightsType {
                                id
                                title
                                slug
                            }
                        }
                    }
                }
                ... on results_Entry {
                    id
                    title
                    typeHandle
                    statistics {
                        value
                        standartLabel
                        highlightedLabel
                    }
                }
                ... on video_Entry {
                    id
                    title
                    typeHandle
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                    mediaVideo {
                        id
                        title
                        url
                        kind
                    }
                    videoLink
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'project', 'query'),
    projects: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query projects($siteId: [QueryArgument], $offset: Int, $department: [String]) {
    projects: entries(
        section: ["projects"]
        siteId: $siteId
        offset: $offset
        limit: 36
        relatedToCategories: { slug: $department }
    ) {
        ... on project_Entry {
            id
            title
            slug
            typeHandle
            projectType {
                id
                title
                slug
            }
            projectMedia {
                id
                title @parseRefs
                kind
                webp: url
                    @transform(mode: "crop", format: "webp", width: 900, height: 1100, quality: 75, immediately: false)
            }
        }
    }
    projectsCount: entryCount(section: "projects", siteId: $siteId, relatedToCategories: { slug: $department })
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'projects', 'query'),
    search: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query search($siteId: [QueryArgument], $query: String) {
    items: entries(
        search: $query
        siteId: $siteId
        section: ["consultancy", "homeConsultancy", "insights", "projects", "solutions", "vacancies"]
        orderBy: "score, title ASC"
    ) {
        id
        slug
        title
        typeHandle
        sectionHandle
        postDate @formatDateTime(format: "d-m-Y")
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search', 'query'),
    settings: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query settings($siteId: [QueryArgument]) {
    settings: globalSets(siteId: $siteId) {
        ... on settings_GlobalSet {
            language
            copyright @parseRefs
            copyrightDevelopment @parseRefs
            offices {
                place
                address @parseRefs
                main
            }
            linkedin
            emailGroup
            emailConsultancy
            emailDevelopment
            linkedinDevelopment
            phoneDevelopment {
                linkUrl
                linkText
            }
            phone {
                linkUrl
                linkText
            }
            hiringLink {
                linkText
                linkUrl
                target
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'settings', 'query'),
    solution: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query solution($siteId: [QueryArgument], $slug: [String]) {
    solution: entry(section: "solutions", siteId: $siteId, slug: $slug) {
        ... on solutions_Entry {
            id
            title
            slug
            typeHandle
            language
            localized {
                language
                slug
            }
            singleImage {
                id
                title
                url
                webp: url @transform(format: "webp", mode: "crop", width: 1900, height: 1300, quality: 80)
            }
            introText @parseRefs
            solutions {
                ... on solution_Entry {
                    title @parseRefs
                    description @parseRefs
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                }
            }
            conceptualLabel @parseRefs
            conceptualHeadline @parseRefs
            conceptualText @parseRefs
            contactHeadline
            contactText
            contactLink {
                isEmpty
                isElement
                element {
                    id
                    title
                    slug
                    ... on homeConsultancy_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contactTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutConsultancyTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on aboutGroupTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on teamTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on contentTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on careersTemplate_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on projectOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insightsOverview_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on insights_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on project_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solution_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on solutions_Entry {
                        sectionHandle
                        typeHandle
                    }
                    ... on vacancies_Entry {
                        sectionHandle
                        typeHandle
                    }
                }
                type
                target
                text
                url
            }
            contactPerson {
                ... on team_Entry {
                    id
                    title
                    slug
                    employeeGroup {
                        id
                        title
                        slug
                    }
                    employeePhoto {
                        id
                        title
                        url
                        webp: url @transform(mode: "crop", format: "webp", width: 650, height: 650, quality: 75)
                    }
                    employeeLinkedin
                    employeePosition
                    employeeEmail
                    employeeDescription
                }
            }
            projectsTitle @parseRefs
            projects {
                id
                title
                slug
                ... on project_Entry {
                    projectMedia {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url
                            @transform(
                                mode: "crop"
                                format: "webp"
                                width: 840
                                height: 1120
                                quality: 75
                                immediately: true
                            )
                    }
                    projectType {
                        id
                        title
                        slug
                    }
                }
            }
            accordionTitle @parseRefs
            accordionText
            accordionItems {
                ... on accordion_Entry {
                    id
                    title
                    description
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                }
            }
            cta {
                ... on cta_Entry {
                    title
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    color
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'solution', 'query'),
    vacancy: (variables = undefined, requestHeaders = undefined) => withWrapper((wrappedRequestHeaders) => client.request(`query vacancy($siteId: [QueryArgument], $slug: [String]) {
    vacancy: entry(section: "vacancies", siteId: $siteId, slug: $slug) {
        ... on vacancies_Entry {
            id
            title
            slug
            typeHandle
            language
            localized {
                language
                slug
            }
            team {
                ... on team_Entry {
                    id
                    title
                    employeePhoto {
                        id
                        title
                        url
                        webp: url @transform(mode: "crop", format: "webp", width: 120, height: 120, quality: 75)
                    }
                    employeeEmail
                    employeePosition
                    employeeLinkedin
                    employeeDescription @parseRefs
                }
            }
            information @parseRefs
            contentElements {
                ... on body_Entry {
                    id
                    text @parseRefs
                    typeHandle
                }
                ... on contactForm_Entry {
                    id
                    title
                    typeHandle
                    mediaImage {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", quality: 75, width: 500, height: 600, mode: "crop")
                    }
                    file {
                        id
                        title
                        filename
                        url
                    }
                    description @parseRefs
                    privacy
                    recipients {
                        email
                    }
                }
                ... on contactPerson_Entry {
                    id
                    headline @parseRefs
                    typeHandle
                    directLink {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    description
                    contactPerson {
                        ... on team_Entry {
                            id
                            title
                            employeePhoto {
                                id
                                title
                                url
                                webp: url @transform(format: "webp", quality: 75)
                            }
                            employeeEmail
                            employeePosition
                            employeeLinkedin
                            employeeDescription @parseRefs
                        }
                    }
                    showEmail
                }
                ... on cta_Entry {
                    id
                    title
                    typeHandle
                    links {
                        isEmpty
                        isElement
                        element {
                            id
                            title
                            slug
                            ... on homeConsultancy_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contactTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutConsultancyTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on aboutGroupTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on teamTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on contentTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on careersTemplate_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on projectOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insightsOverview_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on insights_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on project_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solution_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on solutions_Entry {
                                sectionHandle
                                typeHandle
                            }
                            ... on vacancies_Entry {
                                sectionHandle
                                typeHandle
                            }
                        }
                        type
                        target
                        text
                        url
                    }
                    simple
                    color
                }
                ... on gallery_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        webp: url @transform(format: "webp", width: 1600, mode: "crop", quality: 75)
                    }
                }
                ... on imageLine_Entry {
                    id
                    title
                    typeHandle
                    gallery {
                        id
                        title @parseRefs
                        url
                        kind
                        ... on AssetInterface {
                            extension
                        }
                        webp: url @transform(format: "webp", quality: 75)
                    }
                }
                ... on embed_Entry {
                    id
                    typeHandle
                    embed
                }
                ... on image_Entry {
                    id
                    title
                    typeHandle
                    smaller
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                }
                ... on relatedItems_Entry {
                    id
                    title
                    typeHandle
                    related {
                        id
                        title
                        slug
                        typeHandle
                        postDate @formatDateTime(format: "d-m-Y")
                        ... on project_Entry {
                            projectType {
                                id
                                title
                                slug
                            }
                            projectMedia {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url
                                    @transform(mode: "crop", format: "webp", width: 900, height: 1100, quality: 75)
                            }
                        }
                        ... on insights_Entry {
                            previewImage {
                                id
                                title @parseRefs
                                url
                                kind
                                webp: url @transform(mode: "crop", format: "webp", width: 900, height: 600, quality: 75)
                            }
                            insightsType {
                                id
                                title
                                slug
                            }
                        }
                    }
                }
                ... on results_Entry {
                    id
                    title
                    typeHandle
                    statistics {
                        value
                        standartLabel
                        highlightedLabel
                    }
                }
                ... on video_Entry {
                    id
                    title
                    typeHandle
                    mediaImage @transform(format: "webp", quality: 75) {
                        id
                        title @parseRefs
                        url
                        webp: url @transform(format: "webp", quality: 75, width: 2560, mode: "crop")
                        srcset(sizes: ["1180", "1920", "2560", "3840"])
                    }
                    mediaVideo {
                        id
                        title
                        url
                        kind
                    }
                    videoLink
                }
            }
            seo {
                title
                description
                keywords {
                    keyword
                    rating
                }
                social {
                    twitter {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                    facebook {
                        title
                        description
                        image {
                            id
                            title
                            webp: url @transform(format: "webp", mode: "crop", width: 1200, height: 630, quality: 80)
                        }
                    }
                }
                advanced {
                    robots
                    canonical
                }
            }
        }
    }
}`, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vacancy', 'query')
  }
}