<template>
    <div class="p-splitted-text">
        <component :is="tag">
            <span v-html="content" class="splitted-chars" ref="$splittedText"></span>
        </component>
    </div>
</template>

<script setup>
    const props = defineProps({
        tag: {
            type: String,
            default: "h1"
        },
        content: {
            type: String,
            default: ""
        }
    });

    const $splittedText = ref(null);
    const ctx = ref();
    const store = useGlobalStore();

    watch(
        () => store.pageTransitionComplete,
        (state) => {
            if (state) {
                nextTick(() => (ctx.value = useCtx(useSpilttedAnimation($splittedText.value), $splittedText.value)));
            }
        }
    );

    onBeforeUnmount(() => {
        if (ctx.value) ctx.value.revert();
    });
</script>
