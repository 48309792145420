import { gsap } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from "gsap/SplitText";

export default defineNuxtPlugin((nuxtApp) => {
    gsap.registerPlugin(
        ScrollTrigger,
        ScrollToPlugin,
        SplitText,
        CSSPlugin,
        Draggable,
        InertiaPlugin
    );

    gsap.config({
        force3D: true,
        nullTargetWarn: false,
        trialWarn: false
    })

    ScrollTrigger.config({
        ignoreMobileResize: true
    });

    return {
        provide: {
            gsap,
            ScrollTrigger,
            ScrollToPlugin,
            SplitText,
            CSSPlugin,
            Draggable
        },
    }
})