export function useShapeAnimation(el, type) {
    const { $gsap: gsap } = useNuxtApp()
    
    const timeline = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "0% 100%",
            end: "100% 0%",
            scrub: 0,
        }
    });

    timeline.fromTo(el, {
        scaleY: type == 'grow' ? 0 : 1,
        rotate: 0.001,
    }, {
        scaleY: type == 'grow' ? 1 : 0,
        ease: "none"
    });
}