<template>
    <div class="shape-round" :class="[position, type, style]" ref="$shape"></div>
</template>

<script setup>
    const props = defineProps({
        position: {
            type: String,
            default: "bottom"
        },
        type: {
            type: String,
            default: "grow"
        },
        style: {
            type: String,
            default: "white"
        }
    });

    const $shape = ref();
    const ctx = ref(null);
    const store = useGlobalStore();

    watch(
        () => store.pageTransitionComplete,
        (state) => {
            if (state) {
                setTimeout(() => (ctx.value = useCtx(useShapeAnimation($shape.value, props.type), $shape.value)), 1000);
            }
        }
    );

    onBeforeUnmount(() => {
        if (ctx.value) ctx.value.revert();
    });
</script>
