import Lenis from 'lenis'
import { useGlobalStore } from '~/stores/globalStore';

export const useLenis = (state) => {
    const {
        $gsap: gsap,
        $ScrollTrigger: ScrollTrigger
    } = useNuxtApp();

    const SCROLL_THRESHOLD = 50;
    const SCROLL_THRESHOLD_TOP = 80;

    const store = useGlobalStore();
    const scrollState = useState('scroll-state', () => 0);

    if (store.lenis && state) {
        store.lenis.destroy();
        ScrollTrigger.getAll().forEach(tr => tr.kill());
    }

    if (state) {
        store.setLenis(
            new Lenis({
                smoothWheel: true,
                orientation: 'vertical',
                gestureOrientation: 'vertical',
                normalizeWheel: true
            })
        );

        document.body.dataset.scrollDirection = 'init';
        document.body.dataset.scrolled = 'false';

        store.lenis.on('scroll', ScrollTrigger.update)
        gsap.ticker.add((time) => store.lenis.raf(time * 1000))
        gsap.ticker.lagSmoothing(0);
        store.lenis.scrollTo(0)

        store.lenis.on('scroll', (e) => {
            const targetScroll = e.targetScroll;

            if (Math.abs(scrollState.value - targetScroll) >= SCROLL_THRESHOLD) {
                document.body.dataset.scrollDirection = targetScroll > scrollState.value ? 'down' : 'up';
                document.body.dataset.scrolled = targetScroll >= SCROLL_THRESHOLD_TOP ? 'true' : 'false';
                scrollState.value = targetScroll;
            }
        })
    }
};