export function useSpilttedAnimation(el) {
    const { $gsap: gsap, $SplitText: SplitText } = useNuxtApp();

    const splitted = new SplitText(el,
        {
            type: "lines, chars",
            position: "relative",
            charsClass: "char",
            linesClass: "mask"
        }
    );

    if (splitted.chars.length) {
        gsap.timeline()
            .to(el, {
                opacity: 1,
                duration: 0,
                delay: 0
            })
            .from(splitted.chars, {
                duration: 0.7,
                opacity: 0,
                y: 150,
                ease: "power1.out",
                stagger: 0.02
            });
    }
}