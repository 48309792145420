<template>
    <div class="h-screen">
        <CommonGlobalLoader v-show="!appLoadingComplete" />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup>
    const nuxtApp = useNuxtApp();
    const store = useGlobalStore();
    const { appLoadingComplete, pageTransitionComplete } = storeToRefs(store);
    const { locale } = useI18n();
    const runtimeConfig = useRuntimeConfig();
    const isDesktop = useMediaQuery("(min-width: 1181px)");

    const { data: settings } = await useAsyncGql({
        operation: "settings",
        variables: {
            siteId: [1, 2]
        }
    });

    const { data: menus } = await useAsyncGql({
        operation: currentMenuQuery(runtimeConfig.public.websiteType),
        variables: {
            siteId: [1, 2]
        }
    });

    store.setLanguageCode(locale.value);

    store.setMenus(currentMenuKit(runtimeConfig.public.websiteType, menus.value));
    store.setSettings(currentSettingsKit(settings.value?.settings));

    nuxtApp.hook("app:suspense:resolve", () => {
        setTimeout(() => {
            nuxtApp.$ScrollTrigger.refresh()
        }, 300);
    });

    watch(
        () => pageTransitionComplete.value,
        (state) => {
            nextTick(() => {
                useLenis(state);
                useHeader(state);
            });
        }
    );

    watch(
        () => isDesktop.value,
        (state) => {
            nextTick(() => nuxtApp.$ScrollTrigger.refresh());
            if (state) {
                nextTick(() => {
                    store.setToggleMobileMenu(false);
                });
            }
        }
    );

    // nuxtApp.hook("app:beforeMount", () => console.log("app:beforeMount"));
    // nuxtApp.hook("page:finish", () => console.log("page:finish"));
    // nuxtApp.hook("page:loading:start", () => console.log("page:loading:start"));
    // nuxtApp.hook("page:loading:end", () => console.log("page:loading:end"));
    // nuxtApp.hook("page:transition:finish", () => console.log("page:transition:finish"));
    // nuxtApp.hook("page:start", () => setMobileMenu(false));
    // nuxtApp.hook("page:loading:end", () => {});
</script>
