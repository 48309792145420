<template>
    <div class="h-screen">
        <CommonGlobalLoader v-show="!appLoadingComplete" />
        <NuxtLayout>
            <ContentError />
        </NuxtLayout>
    </div>
</template>

<script setup>
    const nuxtApp = useNuxtApp();
    const store = useGlobalStore();
    const { appLoadingComplete, pageTransitionComplete } = storeToRefs(store);
    const { t, locale } = useI18n();
    const runtimeConfig = useRuntimeConfig();

    const isDesktop = useMediaQuery("(min-width: 1181px)");

    const { data: settings } = await useAsyncGql({
        operation: "settings",
        variables: {
            siteId: [1, 2]
        }
    });

    const { data: menus } = await useAsyncGql({
        operation: currentMenuQuery(runtimeConfig.public.websiteType),
        variables: {
            siteId: [1, 2]
        }
    });

    const seoTitle = computed(() => {
        const titles = {
            group: "Pondera Group",
            consultancy: "Pondera Consultancy",
            development: "Pondera Development"
        };

        return `${t("errors.seo_title")} - ${titles[runtimeConfig.public.websiteType]}`;
    });

    useSeoMeta({
        title: seoTitle.value,
        description: t("errors.seo_description")
    });

    store.setLanguageCode(locale.value);
    store.setMenus(currentMenuKit(runtimeConfig.public.websiteType, menus.value));
    store.setSettings(currentSettingsKit(settings.value?.settings));

    nuxtApp.hook("app:suspense:resolve", () => {
        setTimeout(() => nuxtApp.$ScrollTrigger.refresh(), 300);
    });

    watch(
        () => pageTransitionComplete.value,
        (state) => {
            nextTick(() => {
                useLenis(state);
                useHeader(state);
            });
        }
    );

    watch(
        () => isDesktop.value,
        (state) => {
            nextTick(() => nuxtApp.$ScrollTrigger.refresh());
            if (state) {
                nextTick(() => {
                    store.setToggleMobileMenu(false);
                });
            }
        }
    );
</script>
