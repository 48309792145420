import { default as _91slug_93byiMC79JEuMeta } from "/opt/build/repo/pages/consultancy/[slug].vue?macro=true";
import { default as indexMJHRj9yPocMeta } from "/opt/build/repo/pages/consultancy/index.vue?macro=true";
import { default as _91slug_93GsNEGz5dEnMeta } from "/opt/build/repo/pages/consultancy/news/[slug].vue?macro=true";
import { default as _91slug_9326UkGaxBveMeta } from "/opt/build/repo/pages/consultancy/projects/[slug].vue?macro=true";
import { default as _91slug_932I0DFQbLp3Meta } from "/opt/build/repo/pages/consultancy/solutions/[slug].vue?macro=true";
import { default as _91slug_93i4kv6meTuJMeta } from "/opt/build/repo/pages/consultancy/vacancies/[slug].vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/en/:slug()",
    meta: _91slug_93byiMC79JEuMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___nl",
    path: "/:slug()",
    meta: _91slug_93byiMC79JEuMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexMJHRj9yPocMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/",
    meta: indexMJHRj9yPocMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/index.vue").then(m => m.default || m)
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    meta: _91slug_93GsNEGz5dEnMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___nl",
    path: "/nieuws/:slug()",
    meta: _91slug_93GsNEGz5dEnMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___en",
    path: "/en/projects/:slug()",
    meta: _91slug_9326UkGaxBveMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___nl",
    path: "/projecten/:slug()",
    meta: _91slug_9326UkGaxBveMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "solutions-slug___en",
    path: "/en/solutions/:slug()",
    meta: _91slug_932I0DFQbLp3Meta || {},
    component: () => import("/opt/build/repo/pages/consultancy/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "solutions-slug___nl",
    path: "/oplossingen/:slug()",
    meta: _91slug_932I0DFQbLp3Meta || {},
    component: () => import("/opt/build/repo/pages/consultancy/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacancies-slug___en",
    path: "/en/vacancies/:slug()",
    meta: _91slug_93i4kv6meTuJMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/vacancies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacancies-slug___nl",
    path: "/vacatures/:slug()",
    meta: _91slug_93i4kv6meTuJMeta || {},
    component: () => import("/opt/build/repo/pages/consultancy/vacancies/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/nieuwe-normen-wind-op-land-the-devil-is-in-the-details/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/vacatures/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/vacancies/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/blog-de-impact-van-de-omgevingswet-op-de-houdbaarheid-van-gegevens-niet-nieuw-wel-anders/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/introducing-our-new-colleagues/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/sergio-simanjuntak/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/diensten-voor-initiatiefnemers/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/even-voorstellen-onze-nieuwe-collegas/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/duurzameprojecten/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ons-team-nieuw/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/nieuwe-talenten-versterken-pondera/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/services-for-developers/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/sustainable-projects/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/diensten-voor-initiatiefnemers/plan-project-mer-milieueffectrapportage/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/actueel/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/batterijen-geld-verdienen-met-prijsschommeling/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/pondera-welcomes-new-talent/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/insights/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/de-omgevingswet-welke-procedure-te-volgen-bij-de-ontwikkeling-van-duurzame-energieprojecten/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ponderacontent/accelerating-indonesias-energy-transition-journey/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/de-omgevingswet-inzicht-in-de-vergunningenprocedure-voor-waterstofproductie-installaties/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/eric-arends/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/actualisatie-rekenmethode-omgevingsveiligheid-windturbines/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ponderacontent/new-insights-into-bess-revenue-assessment/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/mogelijk-maximale-hoogte-voor-windturbines-op-zee/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/martijn-ten-klooster/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ponderacontent/offshore-wind-power-in-indonesia/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/groen-licht-voor-windpark-elzenburg-de-geer-in-oss/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/services-for-developers/qhse-health-safety-and-environment-and-quality-qa-qc/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/de-windturbines-van-wp-bovenwind-zijn-officieel-overgenomen-door-wij-windenergie-staphorst/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/services-for-developers/operations-and-maintenance-om-contract/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/vacancies-2/technical-manager-package-manager-offshore-wind/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/vacancies-2/contractmanager-offshore-wind-sun-energy-storage/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ponderacontent/haliade-x-prototype-is-now-a-13-mw-turbine/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ponderacontent/pondera-signs-mou-with-enmaro-in-poland/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/duurzameprojecten/energiepark-pottendijk/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/subsidieregeling-sde-wordt-in-september-opnieuw-opengesteld/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ponderacontent/recommended_maximum_height_for_offshore_windturbines/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/inpassingsplan-windpark-horst-en-telgt-vastgesteld-provincie-schrapt-twee-windturbines/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/vacatures/contractmanager-windenergieprojecten/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/paul-janssen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ervaren-adviseur-energietransitie-en-ruimte/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/directiewisseling-pondera-consult/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/de-energietransitie-in-beeld/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/ontmoet-onze-nieuwe-ponderianen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/cookie-policy-eu/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/maarten-jaspers-faijer/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/working-at/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/pondera-tekent-mou-met-enmaro-in-polen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/vacatures/afstudeerstage-floating-solar/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/de-kleine-lettertjes-van-de-omgevingswet-voor-ontwikkeling-van-windparken/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/pondera-consult-concludes-agreement-for-wando-geumil-offshore-wind-project-in-korea/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/meet-our-new-ponderians/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/limiting-underwater-noise-during-construction-of-wind-farms-in-the-north-sea/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/vacatures/environmental-research-consultant/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/matthias-haag/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/diensten-voor-initiatiefnemers/notitie-reikwijdte-en-detailniveau/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/het-windjaar-2022-gemiddeld-en-grillig/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/hans-rijntalder/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/wind-farm-hollandse-kust-noord/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/privacyverklaring/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/egbert-jansen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/vergunning-verleend-voor-windturbine-landtong-rozenburg/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/jeroen-de-veth/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/diensten-voor-initiatiefnemers/epc-contracten/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ponderacontent/batteries-earning-money-based-on-price-fluctuation/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/services-for-the-government/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/eugene-bajema/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/vacatures/graduationinternship-floating-solar/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/martijn-edink/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/vacatures/electrical-engineer-package-manager-elektricsche-infrastructuur/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/duurzameprojecten/netten-op-zee-ijver-alpha-beta-en-gamma-en-nederwiek-1-2-en-3/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/beperken-onderwatergeluid-bij-aanleg-windparken-noordzee/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/willem-bonnes-2/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/vacatures/transport-en-installatiemanager-offshore-wind/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/luna-jansma/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/marielle-de-sain/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/diensten-voor-overheden/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/dennis-geutjes/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/frank-schellen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/een-ondergrondse-waterkrachtcentrale-als-mega-batterij/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/duurzameprojecten/sea-connections-ijver-alpha-beta-gamma-and-nederwiek-1-2-and-3/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/anne-de-boer/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/versnellen-van-de-energietransitie-in-indonesie/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/diensten-voor-initiatiefnemers/battery-revenue-assessment/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/vacancies/electrical-engineer-on/offshore-package-manager-electrical-infrastructure/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/zonnepark-koegorspolder/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/gitte-hanegraaf/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/nienke-firet/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/vacatures/adviseur-milieuonderzoeken/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/eric-arends/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/windpark-hollandse-kust-noord/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/duurzameprojecten/zonnepark-de-grift-nijmegen-a15/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/erik-holtslag/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/duurzameprojecten/windpark-staphorst/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/jochem-van-der-deen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/jeroen-meisters/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/chandra-soemitro/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/jelle-verploegen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/employees/edo-van-baars/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/anneke-julianita/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/duurzameprojecten/offshore-windpark-jeju-hanlim-zuid-korea/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/onze-werkwijze/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/gertjan-jobse/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/duurzameprojecten/programma-energie-hoofdstructuur/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/brent-elemans/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/category/offshore-wind/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/services-for-developers/power-purchase-agreement-ppa/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/gert-van-der-weijden/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/employees/sebastiaan-janssen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/besluiten-voor-net-op-zee-ijmuiden-ver-gamma-onherroepelijk/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/wij-hebben-weer-nieuwe-ponderianen-erbij/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/sergej-van-de-bilt/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/roy-van-alst/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/mandy-bouwhuis/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/joann-smit/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/matthijs-de-haan/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/ponderacontent/wind-measurements-in-indonesia/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/ponderacontent/windturbinegeluid-en-gezondheid-update/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/laury-renac/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/mark-van-doorn/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/category/view-all/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/janno-heger/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/martijn-groenendaal/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/maarten-sosef/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/employees/mandy-bouwhuis/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/hans-rijntalder/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/sarah-mourad/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/robbert-groenen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/marieke-dirksen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/maarten-graveland/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/jan-willem-broersma/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/rene-vreugdenhil-2/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/egbert-jansen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/bastiaan-bor/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/jasper-vet/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/sofie-van-der-lugt/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/margo-broeren/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/marjolein-pigge/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/bas-verkerk/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/albert-ploeg/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/erik-holtslag/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/mark-rijntalder/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/jop-klaver/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/kegan-sweetnam/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/floris-gemke/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/brent-elemans/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/stefan-flanderijn/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/jasper-vet/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/jeroen-de-veth/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/nynke-keus/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/jorick-amsman/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/ton-huijben/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/dong-khanh-linh/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/bart-ummels/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/joost-sissingh/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/jaehee-park/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/laury-renac/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/renee-van-der-west/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/robbert-groenen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/tom-herfs/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/dion-oude-lansink/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/joep-beijer/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/enriko-weijers/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/suzanne-klerx/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/timo-van-arkel/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/jed-mawson/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/bjorn-konink/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/john-van-de-lagemaat-2/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/rinze_spaanstra/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/albert-ploeg/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/bart-ummels/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/luna-jansma/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/nienke-firet/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/gertjan-jobse/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/chandra-soemitro-2/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/joost-starmans/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/kegan-sweetnam/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/matthias-haag/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/truc-le/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/jelle-verploegen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/wouter-pustjens/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/benjamin-derksen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/werknemers/sarah-mourad/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/rita-wekking/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/werknemers/benjamin-derksen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/jeroen-meisters/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/leila-siebke/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/jochem-van-der-deen-en/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/vivien-liando/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/jorick-amsman/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/martijn-ten-klooster/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/jop-klaver/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/maarten-jaspers-faijer/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/paul-janssen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/joep-beijer/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/marielle-de-sain/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/floris-gemke/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/mark-rijntalder/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/gitte-hanegraaf/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/martijn-edink/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/frank-schellen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/sergej-van-de-bilt/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/edo-van-baars-2/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/margo-broeren/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/rene-vreugdenhil/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/rinze_spaanstra/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/timo-van-arkel/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/wouter-pustjens/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/joost-sissingh/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/mark-rijntalder-2/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/mark-van-doorn/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/sofie-van-der-lugt/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/joost-starmans/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/charlie-huang/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/joann-smit/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/roy-van-alst/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/sebastiaan-janssen/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/ton-huijben/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/enriko-weijers/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/john-van-de-lagemaat/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/judith-weiden/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/bas-verkerk/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/bastiaan-bor/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/carola-klopman/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/carola-roescher/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/gert-van-der-weijden/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/jan-willem-broersma/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/maarten-graveland/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/maarten-sosef/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/manuela-lourenco-sotero/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/martijn-ten-klooster-2/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/matthijs-de-haan/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en/employees/stefan-flanderijn/",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubRU5sOBTecH
  }
]