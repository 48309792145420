<template>
    <section class="c-template-error" data-shape>
        <div class="c-grid">
            <div class="cell sm-12 md-o-1 md-10 lg-o-2 lg-8 xl-o-3 xl-6">
                <ElementsSplittedText :tag="'h2'" :content="$t('errors.headline')" />
                <div class="content">
                    <ClientOnly>
                        <i18n-t keypath="errors.description" scope="global">
                            <template v-slot:home>
                                <NuxtLink
                                    :to="
                                        localePath({
                                            name: 'index'
                                        })
                                    "
                                    :title="$t('errors.link_title_home')"
                                    >{{ $t("errors.link_title_home") }}</NuxtLink
                                >
                            </template>
                        </i18n-t>
                    </ClientOnly>
                </div>
            </div>
        </div>

        <ElementsShape :position="'bottom'" :type="'grow'" :style="'dark'" />
    </section>
</template>

<script setup>
    const localePath = useLocalePath();
    const store = useGlobalStore();

    onUnmounted(() => {
        store.setAppLoadingComplete(false);
        store.setPageTransitionComplete(false);
        clearError({});
    });
</script>
