import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["categoryInsights","categoryProjects","teamCategories","chome","cmenus","cpage","dhome","dmenus","dpage","employeeinsights","employees","ghome","gmenus","gpage","insight","insights","project","projects","search","settings","solution","vacancy"]}
export const GqlCategoryInsights = (...params) => useGql()('categoryInsights', ...params)
export const GqlCategoryProjects = (...params) => useGql()('categoryProjects', ...params)
export const GqlChome = (...params) => useGql()('chome', ...params)
export const GqlCmenus = (...params) => useGql()('cmenus', ...params)
export const GqlCpage = (...params) => useGql()('cpage', ...params)
export const GqlDhome = (...params) => useGql()('dhome', ...params)
export const GqlDmenus = (...params) => useGql()('dmenus', ...params)
export const GqlDpage = (...params) => useGql()('dpage', ...params)
export const GqlEmployeeinsights = (...params) => useGql()('employeeinsights', ...params)
export const GqlEmployees = (...params) => useGql()('employees', ...params)
export const GqlGhome = (...params) => useGql()('ghome', ...params)
export const GqlGmenus = (...params) => useGql()('gmenus', ...params)
export const GqlGpage = (...params) => useGql()('gpage', ...params)
export const GqlInsight = (...params) => useGql()('insight', ...params)
export const GqlInsights = (...params) => useGql()('insights', ...params)
export const GqlProject = (...params) => useGql()('project', ...params)
export const GqlProjects = (...params) => useGql()('projects', ...params)
export const GqlSearch = (...params) => useGql()('search', ...params)
export const GqlSettings = (...params) => useGql()('settings', ...params)
export const GqlSolution = (...params) => useGql()('solution', ...params)
export const GqlTeamCategories = (...params) => useGql()('teamCategories', ...params)
export const GqlVacancy = (...params) => useGql()('vacancy', ...params)