import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        settings: {},
        projectsTypes: {},
        insightsTypes: {},
        teamTypes: {},
        lenis: null,
        pageTransitionBeforeComplete: false,
        pageTransitionComplete: false,
        appLoadingComplete: false,
        toggleSideForm: false,
        toggleSearchForm: false,
        pageWithHero: false,
        employee: {},
        languageCode: 'en',

        // menus 
        menus: [],
        mobileMenuToggle: false,
    }),
    actions: {
        setLenis(payload) {
            this.lenis = payload;
        },
        setToggleSideForm(payload) {
            this.toggleSideForm = payload;
        },
        setAppLoadingComplete(payload) {
            this.appLoadingComplete = payload;
        },
        setPageTransitionBeforeComplete(payload) {
            this.pageTransitionBeforeComplete = payload;
        },
        setPageTransitionComplete(payload) {
            this.pageTransitionComplete = payload;
        },
        setEmployee(payload) {
            this.employee = payload;
        },
        setPageWithHero(payload) {
            this.pageWithHero = payload
        },
        setSettings(payload) {
            this.settings = payload;
        },
        setMenus(payload) {
            this.menus = payload;
        },
        setToggleMobileMenu(payload) {
            this.mobileMenuToggle = payload;
        },
        setToggleSearchForm(payload) {
            this.toggleSearchForm = payload;
        },
        setLanguageCode(payload) {
            this.languageCode = payload;
        },
        toggleMobileMenu() {
            this.mobileMenuToggle = !this.mobileMenuToggle;
        }
    },
})