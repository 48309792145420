<template>
    <div class="p-global-loader loader">
        <div class="p-page-loader--screen">
            <div class="shape-round top grey"></div>
            <div class="shape-round bottom grey"></div>
        </div>
        <div class="p-logo">
            <IconsSmallLogoDevelopment v-if="config.public.websiteType == 'development'" :fill="'white'" />
            <IconsSmallLogo v-else :fill="'white'" />
        </div>
    </div>
</template>

<script setup>
    const { $gsap: gsap } = useNuxtApp();
    const store = useGlobalStore();
    const config = useRuntimeConfig();

    onMounted(() => {
        gsap.set(".p-global-loader .p-page-loader--screen", {
            opacity: 1,
            yPercent: 0
        });

        gsap.set(".p-global-loader .p-page-loader--screen .shape-round.top", {
            scaleY: 0
        });

        gsap.set(".p-global-loader .p-logo", {
            scale: 0
        });

        gsap.timeline({
            onComplete: () => {
                nextTick(() => {
                    store.setAppLoadingComplete(true);
                    store.setPageTransitionComplete(true);
                    document.querySelector(".lenis-content").classList.remove("is-global-loading");
                });
            }
        })
            .to(".p-global-loader .p-logo", {
                duration: 1,
                scale: 1,
                ease: "Expo.easeInOut"
            })
            .to(".p-global-loader .p-logo", {
                duration: 1,
                y: "-80vh",
                ease: "Expo.easeInOut"
            })
            .to(
                ".p-global-loader .p-page-loader--screen",
                {
                    duration: 1,
                    delay: 0.1,
                    yPercent: -100,
                    ease: "Expo.easeInOut"
                },
                "<"
            )
            .to(
                ".p-global-loader .shape-round.top",
                {
                    duration: 0.4,
                    scaleY: 1,
                    ease: "Expo.easeIn"
                },
                "<"
            )
            .to(
                ".p-global-loader .shape-round.top",
                {
                    duration: 0.4,
                    scaleY: 0,
                    ease: "Expo.easeOut"
                },
                "< 0.5"
            );
    });
</script>
